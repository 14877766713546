import { createSlice } from '@reduxjs/toolkit';
import {storeEncryptedData} from '../../Services/commons'


export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        organization: null,
        message: null,
        error: null,
    },
    reducers: {
        setCredentials: (state, action) => {
            const { message, user_organization } = action.payload;
            state.message = message;
            state.organization = user_organization;
            storeEncryptedData('organization', JSON.stringify(user_organization));
        }
    }
});

export const { setCredentials } = dashboardSlice.actions;

export default dashboardSlice.reducer;

export const selectCurrentOrganization = state => state.dashboard.organization;