import React, { useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { FiHome, FiFile, FiUsers } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown';
import { GetUser, GetToken, GetOrganization, storeEncryptedData, getDecryptedData } from "../../Services/commons";
import { PiSquaresFour } from "react-icons/pi";
import './header.css';

function Header() {
  const currentToken = GetToken();
  const currentUser = GetUser();
  const organization = GetOrganization();
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const [isOpen, setIsOpen] = React.useState(false);
  const activePath = getDecryptedData("activePath") || currentPath;

  const toggle = () => setIsOpen(!isOpen);

  const navigateToOrg = (e) => {
    e.preventDefault()
    storeEncryptedData("showIcon", JSON.stringify(false));
    storeEncryptedData("activeCompany", "");
    navigate("/v2/organization")
    storeEncryptedData("activePath", "/v2/organization");
  }

  const handleSetActivePath = (path) => {
    storeEncryptedData("activePath", path);
  };

  // Helper function to get active color
  const getColor = (path) => {
    const activePath = getDecryptedData("activePath") || window.location.pathname;
    return activePath === path ? "#052ebd" : "#101010";
  }

  // Navigate to home and set path
  const navigateToHome = () => {
    storeEncryptedData("showIcon", JSON.stringify(false));
    storeEncryptedData("activeCompany", "");
    navigate("/v2/dashboard");
    storeEncryptedData("activePath", "/v2/dashboard");
  }

  // Handle navigation click
  const handleNavClick = (path) => {
    storeEncryptedData("showIcon", JSON.stringify(false));
    storeEncryptedData("activeCompany", "");
    navigate(path);
    storeEncryptedData("activePath", path);
  }

  // Handle organization selection
  const handleSelectOrg = (e) => {
    storeEncryptedData("activeCompany", e);
    storeEncryptedData("showIcon", JSON.stringify(true));
    storeEncryptedData("activePath", "/v2/company-profile");
  }

  return (
    <Navbar expand="lg" style={{ paddingLeft: '4%', paddingRight: '4%' }}>
      {currentUser?.password_reset && <Navbar.Brand onClick={() => navigateToHome()} style={{ marginLeft: '10px' }}>
        <img src="/alvinlogo1.svg" alt="Alvin logo" style={logo} />
      </Navbar.Brand>}
      <Container fluid style={{ display: 'flex', justifyContent: organization?.onboarding_completed ? 'center' : 'flex-end', alignItems: 'center' }}>
        {organization?.onboarding_completed && <Nav className="mx-auto" style={{ alignItems: 'center' }}>
          <Nav.Item style={{ display: 'flex', alignItems: 'center', marginRight: '2.5rem' }} onClick={() => handleNavClick("/v2/dashboard")}>
            <div className="icon-container">
              <PiSquaresFour style={{ color: getColor("/v2/dashboard"), transform: 'scale(120%)' }} />
            </div>
            <Nav.Link style={{ color: getColor("/v2/dashboard"), fontWeight: "500" }}>Overview</Nav.Link>
          </Nav.Item>
          <CustomDropdown options={[organization.name]} onSelect={handleSelectOrg} handleSetActivePath={handleSetActivePath} />
          <Nav.Item style={{ display: 'flex', alignItems: 'center', marginRight: '2.5rem', color: getColor("/v2/documentation") }} onClick={() => handleNavClick("/v2/documentation")}>
            <div className="icon-container">
              <FiFile />
            </div>
            <Nav.Link style={{ color: getColor("/v2/documentation"), fontWeight: "500" }}>Docs</Nav.Link>
          </Nav.Item>
          <Nav.Item style={{ display: 'flex', alignItems: 'center' }} onClick={(e) => navigateToOrg(e)}>
            <div className="icon-container">
              <FiUsers style={{ color: getColor("/v2/organization") }} />
            </div>
            <Nav.Link style={{ color: getColor("/v2/organization"), fontWeight: "500" }}>Team</Nav.Link>
          </Nav.Item>
        </Nav>}
      </Container>
      {currentUser && <ProfileDropdown username={currentUser?.first_name + " " + currentUser?.last_name} email={currentUser?.email} />}
    </Navbar>
  );
}

const logo = {
  height: "62px",
  marginLeft: "5px",
  cursor: "pointer",
};

export default Header;