import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCredentials } from './AuthSlice';
import { getDecryptedData } from '../../Services/commons';

export const useAuthState = () => {
    const dispatch = useDispatch();
    const [authState, setAuthState] = useState({
        user: getDecryptedData("user") ? JSON.parse(getDecryptedData("user")) : null,
        token: getDecryptedData("token"),
        isLoading: true
    });

    // Listen for storage events (when localStorage changes in other tabs)
    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === 'user' || e.key === 'token') {
                const user = getDecryptedData("user");
                const token = getDecryptedData("token");

                const newState = {
                    user: user ? JSON.parse(user) : null,
                    token: token,
                    isLoading: false
                };

                setAuthState(newState);
                // Update Redux store
                dispatch(setCredentials(newState));
            }
        };

        window.addEventListener('storage', handleStorageChange);
        setAuthState(prev => ({...prev, isLoading: false }));

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [dispatch]);

    return authState;
};