import "./App.css";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Components/Layout";
import Header from "./Components/Header/Header";
import { useSelector } from "react-redux";
import { selectCurrentUser, selectCurrentToken } from "./Features/Auth/AuthSlice";
import { getDecryptedData } from "./Services/commons";
import CustomLoader from "./Components/Loader/CustomLoader";
import NetworkConnectionHandler from "./Components/NetworkConnectionHandler/NetworkConnectionHandler";
import { QueryClient, QueryClientProvider } from "react-query";
import { useAuthState } from "./Features/Auth/useAuthState";

// Lazy-loaded components
const Dashboard = lazy(() => import("./Components/Dashboard/Dashboard"));
const SuccessEmailVerificationPage = lazy(() =>
  import("./Pages/SuccessEmailVerificationPage")
);
const DataEnrichmentPage = lazy(() => import("./Pages/DataEnrichmentPage"));
const DataEnrichmentDemoPage = lazy(() =>
  import("./Pages/DataEnrichmentDemoPage")
);
const SdkKeysPage = lazy(() => import("./Pages/SdkKeysPage"));
const ResetPasswordPage = lazy(() => import("./Pages/ResetPasswordPage"));
const CompanyProfilePage = lazy(() => import("./Pages/CompanyProfilePage"));
const AuthPage = lazy(() => import("./Pages/AuthPage"));
const UpdatePasswordPage = lazy(() => import("./Pages/UpdatePasswordPage"));
const OrganizationProfilePage = lazy(() =>
  import("./Pages/OrganizationProfilePage")
);
const SuccessfulInvitationConfirmationPage = lazy(() =>
  import("./Pages/SuccessfulInvitationConfirmationPage")
);
const EmailVerificationPage = lazy(() => import("./Pages/EmailVerification"));
const OnboardingContainer = lazy(() =>
  import("./Components/CompanyOnboarding/OnboardingContainer")
);
const DocumentationPage = lazy(() => import("./Pages/DocumentationPage"));

const App = () => {
  const queryClient = new QueryClient();
  const { user: currentUser, token, isLoading } = useAuthState();

  if (isLoading) return <CustomLoader />;

  return (
    <QueryClientProvider client={queryClient}>
      <div className="max-w-full">
        <ToastContainer
          position="top-right"
          autoClose={500}
          hideProgressBar
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <NetworkConnectionHandler>
          <Suspense
            fallback={
              <div className="overlay" style={overlayDiv}>
                <CustomLoader />
              </div>
            }
          >
            <Header user={currentUser} />
            <Routes>
              <Route path="/" element={<Navigate to="/v2/" />} />
              <Route path="/v2" element={<Layout />}>
                <Route index element={<AuthPage />} />
                <Route
                  path="email-verification/:slug"
                  element={<SuccessEmailVerificationPage />}
                />
                <Route
                  path="invitation/:slug"
                  element={<SuccessfulInvitationConfirmationPage />}
                />
                <Route
                  path="password-update"
                  element={<UpdatePasswordPage />}
                />
                <Route
                  path="password-reset/:slug"
                  element={<ResetPasswordPage />}
                />
                <Route
                  path="email-verification"
                  element={<EmailVerificationPage />}
                />
                <Route path="dashboard" element={<Dashboard />} />
                <Route
                  path="onboarding"
                  element={<OnboardingContainer />}
                />
                <Route
                  path="enriched-data"
                  element={<DataEnrichmentPage />}
                />
                <Route
                  path="enriched-data-demo"
                  element={<DataEnrichmentDemoPage />}
                />
                <Route path="api-keys" element={<SdkKeysPage />} />
                <Route
                  path="company-profile"
                  element={<CompanyProfilePage />}
                />
                <Route
                  path="organization"
                  element={<OrganizationProfilePage />}
                />
                <Route
                  path="documentation"
                  element={<DocumentationPage />}
                />
              </Route>
            </Routes>
          </Suspense>
        </NetworkConnectionHandler>
      </div>
    </QueryClientProvider>
  );
};

export default App;

/* CSS (App.css) */
const overlayDiv = {
  position: "absolute",
  top: "0",
  left: "3%",
  width: "100%",
  height: "100%",
  zIndex: "1",
};