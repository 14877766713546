import React from 'react';
import loaderSvg from '../../assets/Loader.svg';
import './loader.css';

const CustomLoader = () => {
    return (
        <div className="spinnerWrap">
            <div className="spinner">
                <img src={loaderSvg} alt="loader" id="loader-image"/>
            </div>
        </div>
    );
}

export default CustomLoader;