import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/ApiSlice";
import authReducer from "../Features/Auth/AuthSlice";
import dashboardReducer from "../Features/Dashboard/DashboardSlice";

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        dashboard: dashboardReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
});