import { createSlice } from '@reduxjs/toolkit';
import { clearCache } from '../../Helper/ApiEnpoints'
import {storeEncryptedData} from '../../Services/commons'
import { createSelector } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        token: null,
        error: null,
    },
    reducers: {
        setCredentials: (state, action) => {
            const { token, user } = action.payload;
            state.user = user;
            state.token = token;
            storeEncryptedData('token', token);
            storeEncryptedData('user', JSON.stringify(user));
        },
        logOut: (state, action) => {
            clearCache()
            try {
                state.user = null;
                state.token = null;
            } catch (e) {
                console.log(e);
            }
        },
    }
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectAuth = state => state.auth;

export const selectCurrentUser = createSelector(
  [selectAuth],
  auth => auth.user
);

export const selectCurrentToken = createSelector(
  [selectAuth],
  auth => auth.token
);