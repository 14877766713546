import { postRequest, getRequest } from "./ApiCalls";
import lru from 'quick-lru';
import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_NODE_ENV === "production" ?
    "https://finance.app.alvinapp.com" :
    process.env.REACT_APP_API_NODE_ENV === "staging" ?
        "https://ent.app.alvinapp.com" :
        process.env.REACT_APP_API_BASE_URL

export const API_ENDPOINTS = {
    LOGIN: "users/login/",
    REGISTER: "/users/",
    VERIFY_EMAIL: "/verify-email",
    RESEND_VERIFICATION_EMAIL: "/resend_confirmation/",
    FORGOT_PASSWORD: "/forgot-password",
    ORGANIZATION: "users/organization/",
    ORGANIZATION_WITH_ID: "users/organization/{organization_id}/workspace",
    DEMO_DATA: "transactions/csv/",
    ORGANIZATION_USERS: "users/organization/",
    DELETE_USER: "users/delete",
    CATEGORIZED_TRANSACTIONS: "transactions/categorized_transactions/",
    TOTAL_TRANSACTION_PER_CATEGORY: "transactions/statistics/category_totals",
    ONBOARDING_STEPS: "onboarding_steps/organization/{organization_id}",
    ONBOARDING_STEP_DETAIL: "onboarding_steps/",
    ONBOARDING_STEP_CALL_DETAILS: "onboarding_steps/{step_id}/call",
    ORGANIZATION_DOCUMENTS: "onboarding_steps/document/{organization_id}",
    UPLOAD_SIGNED_SLA: "onboarding_steps/document/{organization_id}/upload_document",
    USER_DEMO_DETAILS: "users/demo_details",
    GET_USER_ORGANIZATIONS: "users/organization/{email}",
}

const CACHE_EXPIRATION_TIME = 15000; // 15 seconds

// Create a simple in-memory cache object
const cache = {};

// Function to generate cache key
const createCacheKey = (organizationId, apiKey, page, keyword) =>
    `${organizationId}:${apiKey}:${page}:${keyword}`;

// Function to set cache with timestamp
const setCache = (key, data) => {
    const now = Date.now();
    cache[key] = { data, timestamp: now };
};

// Function to get cache with expiration check
const getCache = (key) => {
    const cacheEntry = cache[key];
    if (!cacheEntry) return null;

    const isExpired = (Date.now() - cacheEntry.timestamp) > CACHE_EXPIRATION_TIME;
    if (isExpired) {
        delete cache[key];
        return null;
    }

    return cacheEntry.data;
};

const fetchData = async (url, params, token, keyGenerator, errorHandler) => {
    const key = keyGenerator();
    const cacheEntry = getCache(key);

    if (cacheEntry) {
        return cacheEntry;
    }

    try {
        const response = await axios.get(url, {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        setCache(key, response.data);
        return response.data;
    } catch (error) {
        throw new Error(errorHandler(error));
    }
};

// Modified postData function to use custom cache
const postData = async (url, data, token, keyGenerator, errorHandler) => {
    const key = keyGenerator();
    const cacheEntry = getCache(key);

    if (cacheEntry) {
        return cacheEntry;
    }

    try {
        const response = await axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        setCache(key, response.data);
        return response.data;
    } catch (error) {
        throw new Error(errorHandler(error));
    }
};

// api call for login
export const login = async (email, password) => {
    try {
        const url = `${BASE_URL}/${API_ENDPOINTS.LOGIN}`;
        const data = {
            email,
            password
        }
        const response = await postRequest(url, data);
        return response;
    } catch (error) {
        throw new Error(error);
    }
};

const userOrganizationCacheMap = new Map();
const demoDataCacheMap = new Map();

export const getOrganizationUsers = async (
    token,
    organizationId,
    reload = false
) =>
    postData(
        `${BASE_URL}/${API_ENDPOINTS.ORGANIZATION_USERS}${organizationId}`, {},
        token,
        () => token,
        (error) => error,
        reload
    );

export const getPercentileExpenditure = async (token, userId, startDate, endDate) =>
    fetchData(
        `${BASE_URL}/goals/micros/percentiles/`, { user_id: userId, start_date: startDate, end_date: endDate },
        token,
        () => `${userId}:${startDate}:${endDate}:${token}`,
        (error) => error.message // Improved error handling
    );


export const getRegisteredUsers = async (
    token,
    organizationId,
    startDate,
    endDate
) =>
    fetchData(
        `${BASE_URL}/organizations/${organizationId}/statistics/registered_users`, { start_date: startDate, end_date: endDate },
        token,
        () => `${organizationId}:${startDate}:${endDate}:${token}`,
        (error) => error
    );

export const getAllOrganizationUsers = async (
    token,
    organizationId,
) =>
    fetchData(
        `${BASE_URL}/organizations/${organizationId}/statistics/all_registered_users`, {},
        token,
        () => `${organizationId}:${token}`,
        (error) => error
    );

export const getTotalTransactionPerMicroapplication = async (
    token,
    organizationId,
    microapplication_type,
    startDate,
    endDate
) =>
    fetchData(
        `${BASE_URL}/organizations/${organizationId}/statistics/microapplications`, { start_date: startDate, end_date: endDate, microapplication_type: microapplication_type },
        token,
        () => `${organizationId}:${microapplication_type}:${startDate}:${endDate}:${token}`,
        (error) => error
    );

export const getTotalTransactionPerCategory = async (
    token,
    category,
    startDate,
    endDate
) =>
    fetchData(
        `${BASE_URL}/transactions/statistics/category_totals`, { category, start_date: startDate, end_date: endDate },
        token,
        () => `${category}:${startDate}:${endDate}:${token}`,
        (error) => error
    );

export const getcategoriedTransactionTotal = async (token, startDate, endDate) =>
    fetchData(
        `${BASE_URL}/transactions/statistics/total_transactions`, { end_date: endDate, start_date: startDate },
        token,
        () => `${token}:${startDate}:${endDate}`,
        (error) => error
    );

export const getCategorizedTransactions = async (token, organizationId, apiKey, page, keyword) => {
    const key = createCacheKey(organizationId, apiKey, page, keyword);
    const cached = getCache(key);
    if (cached) {
        return cached;
    }

    const url = `${BASE_URL}/${API_ENDPOINTS.CATEGORIZED_TRANSACTIONS}search?organization_id=${organizationId}&keyword=${keyword}&api_key=${apiKey}&page=${page}`;
    try {
        const response = await getRequest(url, {
            Authorization: `Bearer ${token}`
        });
        setCache(key, response);
        return response;
    } catch (error) {
        throw new Error(error);
    }
};

export const deleteUser = async (token, email) => {
    try {
        const url = `${BASE_URL}/${API_ENDPOINTS.DELETE_USER}`;
        const data = {
            "email": email
        }
        const { data: response } = await postRequest(url, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
};

export const demoData = async (token) =>
    postData(
        `${BASE_URL}/${API_ENDPOINTS.DEMO_DATA}`, {},
        token,
        () => token,
        (error) => error
    );


export const getUserOrganization = async (token) => {
    const key = `userOrganization:${token}`;
    const cached = getCache(key);
    if (cached) {
        return cached;
    }

    try {
        const url = `${BASE_URL}/${API_ENDPOINTS.ORGANIZATION}`;
        const response = await postRequest(url, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setCache(key, response);
        return response;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const getUserOrganizationWithoutCache = async (token) => {
    try {
        const url = `${BASE_URL}/${API_ENDPOINTS.ORGANIZATION}`;
        const response = await postRequest(url, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const getUserOrganizationWithIndexWithoutCache = async (token, organizationIndex) => {
    try{
        organizationIndex = organizationIndex || 0;
        const url = `${BASE_URL}/${API_ENDPOINTS.ORGANIZATION_WITH_ID.replace('{organization_id}', organizationIndex)}`;
        const response = await postRequest(url, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        throw new Error(error.message);
    }
}

export const getUserOrganizationsWithoutCache = async (email) => {
    try {
        const url = `${BASE_URL}/${API_ENDPOINTS.GET_USER_ORGANIZATIONS.replace('{email}', email)}`;
        const response = await getRequest(url);
        return response;
    } catch (error) {
        throw new Error(error.message);
    }
}

export const postDemoDetails = async (token, demo_email) => {
    try {
        const url = `${BASE_URL}/${API_ENDPOINTS.USER_DEMO_DETAILS}`;
        const data = {
            "demo_email": demo_email
        }
        const response = await postRequest(url, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
};

export const getDemoDetails = async (token) => {
    try {
        const url = `${BASE_URL}/${API_ENDPOINTS.USER_DEMO_DETAILS}`;
        const response = await getRequest(url, {
            Authorization: `Bearer ${token}`
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
};

export const getOrganizationUsersWithoutCache = async (token, organizationId) => {
    try {
        const url = `${BASE_URL}/${API_ENDPOINTS.ORGANIZATION_USERS}${organizationId}`;
        const response = await postRequest(
            url, {},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

export const resendVerificationEmail = async (email) => {
    const url = `${API_ENDPOINTS.RESEND_VERIFICATION_EMAIL}`;
    const data = {
        email
    }
    const response = await postRequest(url, data);
    return response;
}

export const postCallDetails = async (token, stepId, callDetails) => {
    try {
        const url = `${BASE_URL}/${API_ENDPOINTS.ONBOARDING_STEP_CALL_DETAILS.replace('{step_id}', stepId)}`;
        const response = await postRequest(url, callDetails, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
}

export const getCallDetails = async (token, stepId) => {
    try {
        const url = `${BASE_URL}/${API_ENDPOINTS.ONBOARDING_STEP_CALL_DETAILS.replace('{step_id}', stepId)}`;
        const response = await getRequest(url, {
            Authorization: `Bearer ${token}`
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
}

export const postOnboardingStep = async (token, organizationId, stepNumber, nextStep, dueDate) => {
    try {
        const url = `${BASE_URL}/${API_ENDPOINTS.ONBOARDING_STEPS.replace('{organization_id}', organizationId)}`;
        const data = {
            step_number: stepNumber,
            next_step: nextStep,
            due_date: dueDate
        }
        const response = await postRequest(url, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
}

export const getOrganizationDocuments = async (token, organizationId) => {
    try {
        const url = `${BASE_URL}/${API_ENDPOINTS.ORGANIZATION_DOCUMENTS.replace('{organization_id}', organizationId)}`;
        const response = await getRequest(url, {
            Authorization: `Bearer ${token}`
        });
        return response;
    } catch (error) {
        throw new Error(error);
    }
}

export const uploadSignedSLADocument = async (token, organizationId, file, additionalData) => {
    try {
        const url = `${BASE_URL}/${API_ENDPOINTS.UPLOAD_SIGNED_SLA.replace('{organization_id}', organizationId)}`;

        // Prepare the form data
        const formData = new FormData();
        formData.append('file', file);
        formData.append('data', JSON.stringify(additionalData)); // Assuming the API expects a JSON string for additional data

        // Headers to set multipart/form-data
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        };

        // Perform the API request
        const response = await postRequest(url, formData, headers);
        return response;
    } catch (error) {
        console.error('Failed to upload SLA document:', error);
        throw new Error('Failed to upload SLA document');
    }
};

export const clearCache = () => {
    for (const key in cache) {
        delete cache[key];
    }
};