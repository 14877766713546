import React, { useState, useEffect } from 'react';

const NetworkConnectionHandler = ({ children }) => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);
    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  return (
    <>
      {!isOnline && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#f7fafc',
          }}
        >
          <h1 style={{ textAlign: 'center' }}>
            Oops! Looks like you're not connected to the internet.
          </h1>
        </div>
      )}
      {isOnline && (children)}
    </>
  );
};

export default NetworkConnectionHandler;
