import axios from "axios";


export async function postRequest(url, payload = {}, headers = {}) {
    try {
        const response = await axios.post(url, payload, headers);
        return response;
    } catch (error) {
        return error.response;
    }
}

export async function getRequest(url, headers = {}) {
    try {
        const response = await axios.get(url, { headers });
        return response.data;
    } catch (error) {
        return error.response;
    }
}

export async function putRequest(url, payload = {}, headers = {}) {
    try {
        const response = await axios.put(url, payload, headers);
        return response;
    } catch (error) {
        return error.response;
    }
}

export async function deleteRequest(url, payload = {}, headers = {}) {
    try {
        const response = await axios.delete(url, payload, headers);
        return response;
    } catch (error) {
        return error.response;
    }
}