import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FiLayers,
  FiChevronDown,
  FiChevronUp,
  FiCheckCircle,
} from "react-icons/fi";
import { throttle } from "lodash";
import "./CustomDropdown.css";
import { storeEncryptedData, getDecryptedData } from "../../Services/commons";

const CustomDropdown = ({ options, onSelect, handleSetActivePath }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const activeCompany = getDecryptedData("activeCompany") || "";
  const activePath = getDecryptedData("activePath") || "";
  // console.log("activeCompany", activeCompany);
  const navigate = useNavigate();
  const location = useLocation();
  const [isSpacesSelected, setIsSpacesSelected] = useState(false);

  const showIconRef = useRef(
    JSON.parse(getDecryptedData("showIcon")) !== null
      ? JSON.parse(getDecryptedData("showIcon"))
      : true
  );

  let showIcon = showIconRef.current;

  useEffect(() => {
    showIconRef.current =
      JSON.parse(getDecryptedData("showIcon")) !== null
        ? JSON.parse(getDecryptedData("showIcon"))
        : true;
  }, []);

  const wrapperRef = useRef(null);
  const optionsListRef = useRef(["alvin demo", ...options]);
  const optionsList = optionsListRef.current;

  useEffect(() => {
    optionsListRef.current = ["alvin demo", ...options];
  }, [options]);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const navigteTo = (index) => {
    navigate("/v2/company-profile", {
      state: {
        company: optionsList[index],
      },
    });
  };

  const setSelectedThenCloseDropdown = (index) => {
    setSelectedOption(index);
    // console.log("index", optionsList[index]);
    storeEncryptedData("activeCompany", optionsList[index]);
    storeEncryptedData("showIcon", JSON.stringify(true));
    setIsOptionsOpen(false);
    setIsSpacesSelected(true); // Mark "Spaces" as selected
    navigteTo(index);

    // Call the callback with the selected option
    if (handleSetActivePath) {
      handleSetActivePath("/v2/company-profile");
      // console.log("handleSetActivePath", handleSetActivePath);
    }
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = throttle((event) => {
      if (!isOptionsOpen) {
        return;
      }
      const dropdownRect = dropdownRef.current?.getBoundingClientRect();
      if (!dropdownRect) {
        return;
      }
      const center = {
        x: dropdownRect.x + dropdownRect.width / 2,
        y: dropdownRect.y + dropdownRect.height / 2,
      };
      const distanceFromCenter = Math.sqrt(
        Math.pow(event.clientX - center.x, 2) +
          Math.pow(event.clientY - center.y, 2)
      );
      if (distanceFromCenter > 160) {
        setIsOptionsOpen(false);
      }
    }, 100);
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [dropdownRef, isOptionsOpen, setIsOptionsOpen]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOptionsOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  const handleListKeyDown = (e) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0
            ? selectedOption - 1
            : optionsList.length - 1
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedOption(
          selectedOption === optionsList.length - 1
            ? 0
            : selectedOption + 1
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Check if the current path is not "/v2/company-profile" or "isSpacesSelected" is false,
    // then reset "isSpacesSelected" to false.
    if (
      location.pathname !== "/v2/company-profile" ||
      !isSpacesSelected
    ) {
      setIsSpacesSelected(false);
    }
  }, [location.pathname, isSpacesSelected]);

  return (
    <div ref={wrapperRef} className="nav-bar-dropdown-container">
      <div className="dropdown-header-" onMouseEnter={toggleOptions}>
        <div className="icon-dropdown-container">
          <FiLayers
            className="dropdown-icon"
            style={{
              color: activePath === "/v2/company-profile" || isSpacesSelected ? "#052ebd" : "#101010",
            }}
          />
        </div>
        <div
          className="dropdown-header-text"
          style={{
            color: activePath === "/v2/company-profile" || isSpacesSelected ? "#052ebd" : "#101010",
          }}
        >
          My projects
        </div>
        <div className="dropdown-header-icon">
          {isOptionsOpen ? (
            <FiChevronUp
              className="dropdown-chevron"
              style={{
                color: "#052ebd",
              }}
            />
          ) : (
            <FiChevronDown
              className="dropdown-chevron"
              style={{
                color: "#101010",
              }}
            />
          )}
        </div>
      </div>
      {isOptionsOpen && (
        <div ref={dropdownRef} className="dropdown-options-container">
          <div
            className="dropdown-options-list"
            onKeyDown={handleListKeyDown}
            onMouseLeave={toggleOptions}
          >
            {optionsList.map((option, index) => (
              <div
                className="dropdown-option-row"
                key={index}
                onClick={() => setSelectedThenCloseDropdown(index)}
                onKeyDown={handleKeyDown(index)}
              >
                <div
                  className="dropdown-option-text"
                  style={{
                    color:
                      showIcon && activeCompany === option
                        ? "#052ebd"
                        : "#101010",
                  }}
                >
                  {option === "alvin demo" ? "Alvin Demo" : option.charAt(0).toUpperCase() + option.slice(1)}
                </div>
                <div className="dropdown-option-icon">
                  {showIcon && activeCompany === option ? (
                    <FiCheckCircle className="dropdown-option-icon" />
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
