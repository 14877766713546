import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../../Features/Auth/AuthSlice';
import { BASE_URL } from '../../Helper/ApiEnpoints';


const BaseQuery = fetchBaseQuery({
    url:`${BASE_URL}`,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    }
})


const BaseQueryWithReauth = async(args, api, extraOptions) => {
    let result = await BaseQuery(args, api, extraOptions);
    if (result.status === 401) {
        const refreshResult = await api.post('/users/login/');
        if (refreshResult?.data) {
            const user = api.getState().auth.user;
            api.dispatch(
                setCredentials({
                    ...refreshResult.data,
                    user
                })
            );
            result = await BaseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logOut());
        }
    }
    return result;
}

export const apiSlice = createApi({
    baseQuery: BaseQueryWithReauth,
    endpoints: builder => ({})
});