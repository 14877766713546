import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import { selectCurrentUser, selectCurrentToken } from '../Features/Auth/AuthSlice';
import { selectCurrentOrganization } from '../Features/Dashboard/DashboardSlice';

export function exportToExcel(data, fileName) {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const newData = new Blob([excelBuffer], { type: 'application/octet-stream' });
    FileSaver.saveAs(newData, fileName);
}


export function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            var dataURL = canvas.toDataURL("image/png");

            resolve(dataURL);
        };

        img.onerror = error => {
            reject(error);
        };

        img.src = url;
    });
}


export const numberWithCommas = (x) => {
    if (typeof x === "undefined") return 0;
    if (typeof x === "string") {
        x = parseFloat(x);
    }
    if (Number.isNaN(x)) return "";
    const [integerPart, decimalPart] = x.toString().split(".");
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (!decimalPart) return formattedIntegerPart;
    return `${formattedIntegerPart}.${decimalPart}`;
};


export const checkUser = (user, email) => {
    if (user.role === "admin") {
        return true;
    } else if (user.email === email) {
        return true;
    } else {
        return false;
    }
}

export function GetUser() {
    let currentUser = useSelector(selectCurrentUser);
    const userLocalStorage = getDecryptedData('user');
    if (typeof userLocalStorage !== 'undefined') {
        try {
            currentUser = JSON.parse(userLocalStorage);
        } catch (e) {
            console.log(e);
        }
    }
    return currentUser;
}

export function GetOrganization() {
    let organization = useSelector(selectCurrentOrganization);
    const organizationLocalStorage = getDecryptedData('organization');
    if (typeof organizationLocalStorage !== 'undefined') {
        try {
            organization = JSON.parse(organizationLocalStorage);
        } catch (e) {
            console.log(e);
        }
    }
    return organization;
}

export function GetToken() {
    let token = useSelector(selectCurrentToken);
    const tokenLocalStorage = getDecryptedData('token');
    if (typeof tokenLocalStorage !== 'undefined') {
        try {
            token = tokenLocalStorage;
        } catch (e) {
            console.log(e);
        }
    }
    return token;
}


export function convertDate(date) {
    const d = new Date(date);
    const day = d.getDate();
    const dayString = day < 10 ? `0${day}` : day;
    const month = d.toLocaleString('default', { month: 'long' });
    const year = d.getFullYear();
    return `${dayString} ${month} ${year}`;
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function dateRangeToString(start, end) {
    const startMonth = months[start.getMonth()];
    const startDay = start.getDate();
    const endMonth = months[end.getMonth()];
    const endDay = end.getDate();
    const currentMonth = months[new Date().getMonth()];
    const currentDay = new Date().getDate();

    if (start > end) {
        throw new Error('Start date is after the end date');
    }

    if (endMonth === currentMonth && endDay === currentDay) {
        return `${startMonth} ${startDay} - Present`;
    }
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
}


export function dateRangeWithYearToString(start, end) {
    const startMonth = months[start.getMonth()];
    const startDay = start.getDate();
    const startYear = start.getFullYear();
    const endMonth = months[end.getMonth()];
    const endDay = end.getDate();
    const endYear = end.getFullYear();
    const currentMonth = months[new Date().getMonth()];
    const currentDay = new Date().getDate();

    if (start > end) {
        throw new Error('Start date is after the end date');
    }

    if (endMonth === currentMonth && endDay === currentDay) {
        return `${startMonth} ${startDay}, ${startYear} - Present`;
    }
    return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
}



export function convertDateString(date) {
    const d = new Date(date);
    const day = d.getDate();
    const dayString = day < 10 ? `4${day}` : day;
    const month = d.toLocaleString('default', { month: 'long' });
    const year = d.getFullYear();
    return `${month} ${dayString} ${year}`;
}

const getStartDate = (today) => new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
const getEndDate = (today) => new Date(today.getFullYear(), today.getMonth(), today.getDate());
const getThisMonthStartDate = (today) => new Date(today.getFullYear(), today.getMonth(), 1);
const getThisMonthEndDate = (today) => new Date(today.getFullYear(), today.getMonth() + 1, 0);
const getLastMonthStartDate = (today) => new Date(today.getFullYear(), today.getMonth() - 1, 1);
const getLastMonthEndDate = (today) => new Date(today.getFullYear(), today.getMonth(), 0);
const getLast3MonthsStartDate = (today) => new Date(today.getFullYear(), today.getMonth() - 3, 1);
const getLast6MonthsStartDate = (today) => new Date(today.getFullYear(), today.getMonth() - 6, 1);
const getThisYearStartDate = (today) => new Date(today.getFullYear(), 0, 1);
const getThisYearEndDate = (today) => new Date(today.getFullYear(), today.getMonth() + 1, 0);
const getAllTimeStartDate = () => new Date(2023, 0, 1);

const options = {
    'Last 30 days': {
        startDate: getStartDate,
        endDate: getEndDate
    },
    'This month': {
        startDate: getThisMonthStartDate,
        endDate: getEndDate
    },
    'Last month': {
        startDate: getLastMonthStartDate,
        endDate: getLastMonthEndDate
    },
    'Last 3 months': {
        startDate: getLast3MonthsStartDate,
        endDate: getEndDate
    },
    'Last 6 months': {
        startDate: getLast6MonthsStartDate,
        endDate: getEndDate
    },
    'This year': {
        startDate: getThisYearStartDate,
        endDate: getEndDate
    },
    'All time': {
        startDate: getAllTimeStartDate,
        endDate: getEndDate
    }
};

export function getDateRange(selectedOption, today) {
    today = today || new Date();
    today.setHours(0, 0, 0, 0);
    const { startDate, endDate } = options[selectedOption] || options['Last 30 days'];
    const dateRangeString = dateRangeToString(
        startDate(today),
        endDate(today)
    )
    return {
        startDate: startDate(today),
        endDate: endDate(today),
        dateRange: dateRangeString,
    };
}


export function convertDateToISO(date) {
    const d = new Date(date);
    const day = d.getDate();
    const dayString = day < 10 ? `0${day}` : day;
    const month = d.getMonth() + 1;
    const monthString = month < 10 ? `0${month}` : month;
    const year = d.getFullYear();
    return `${year}-${monthString}-${dayString}`;
}

export function getDataInOrder(data) {
    const order = [
        'Rent/Mortgage',
        'Bills & Fees',
        'Groceries',
        'Health',
        'Transport & Fuel',
        'Data & WiFi',
        'Education',
        'Shopping',
        'Family & Friends',
        'Going out',
        'Miscellaneous',
        'Emergency fund'
    ];
    const result = order.map(key => {
        const item = data[key];
        return item ? item.total_amount : 0;
    });
    return result;
}

export function getUsersInOrder(data) {
    const order = [
        'Rent/Mortgage',
        'Bills & Fees',
        'Groceries',
        'Health',
        'Transport & Fuel',
        'Data & WiFi',
        'Education',
        'Shopping',
        'Family & Friends',
        'Going out',
        'Miscellaneous',
        'Emergency fund'
    ];
    const result = order.map(key => {
        const item = data[key];
        return item ? item.total_users : 0;
    });
    return result;
}


export function truncateNumber(num, fixedValue = 1) {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(fixedValue) + "M";
    } else if (num >= 1000) {
        return (num / 1000).toFixed(fixedValue) + "K";
    } else {
        return num.toFixed(fixedValue).toString();
    }
}


// Encryption function
function encrypt(value) {
    const encryptedValue = btoa(value);
    return encryptedValue;
}

// Decryption function
function decrypt(encryptedValue) {
    const value = atob(encryptedValue);
    return value;
}

// Function to store encrypted data in local storage
export function storeEncryptedData(key, value) {
    const encryptedValue = encrypt(value);
    localStorage.setItem(key, encryptedValue);
}

// Function to retrieve decrypted data from local storage
export function getDecryptedData(key) {
    const encryptedValue = localStorage.getItem(key);
    if (!encryptedValue) {
        return null;
    }
    const value = decrypt(encryptedValue);
    return value;
}

export function generateNextNumber(arr) {
    // Calculate the probability distribution for the amount to increase the number
    const probabilityDistribution = [0.25, 0.3, 0.35];

    // Get the last number in the array
    const lastNumber = arr[arr.length - 1];

    // Generate a random number between 0 and 1 to determine the amount to increase the number
    const rand = Math.random();

    // Determine the index of the probability distribution to use based on the random number
    let probIndex = 0;
    while (probIndex < probabilityDistribution.length - 1 && rand > probabilityDistribution[probIndex]) {
        probIndex++;
    }

    // Calculate the amount to increase the number based on the probability distribution
    const increaseAmount = lastNumber * probabilityDistribution[probIndex];

    // Generate the next number by adding the increase amount to the last number
    const nextNumber = lastNumber + increaseAmount;

    return nextNumber;
}

export function createMonthArrays(expenses) {
    const monthNames = [];
    const monthTotals = [];
    const monthUserTotals = [];
  
    const sortedMonths = Object.entries(expenses).sort(
      (a, b) =>
        new Date(`${a[1].month_year} ${a[0]}`).getTime() -
        new Date(`${b[1].month_year} ${b[0]}`).getTime()
    );
  
    let monthNum = -1;
    let prevYear = "";
  
    for (const [monthName, { month_total, month_user_total, month_year }] of sortedMonths) {
      const [month, year] = [monthName, month_year];
      if (prevYear !== year) {
        monthNum = -1;
        prevYear = year;
      }
      const monthIndex = monthNames.indexOf(month);
      if (monthIndex === -1) {
        monthNames.push(month);
        monthTotals.push(parseFloat(month_total));
        monthUserTotals.push(month_user_total);
        monthNum++;
      } else {
        monthTotals[monthNum] += parseFloat(month_total);
        monthUserTotals[monthNum] += month_user_total;
      }
    }
  
    return { monthNames, monthTotals, monthUserTotals };
  }
  
  

export function isCurrentMonthLastShortMonth(months) {
    const date = new Date();
    const currentMonth = date.toLocaleString('default', { month: 'short' });
    const lastMonth = months[months.length - 1];
    return currentMonth === lastMonth;
}

export function getMonthRange(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const result = [];

    let month = start.getMonth();
    let year = start.getFullYear();

    while (year < end.getFullYear() || (year === end.getFullYear() && month <= end.getMonth())) {
        result.push(months[month]);

        month++;
        if (month > 11) {
            month = 0;
            year++;
        }
    }

    return result;
}

export function convertDatesToTimestamps(dates) {
    const timestamps = dates.map(dateString => {
        const date = new Date(dateString);
        return date.getTime();
    });
    return timestamps;
}

export function convertDateToTimestamp(dateString) {
    const date = new Date(dateString);
    return date.getTime();
}

export const createDurationString = (start_time, end_time) => {
    const start = new Date(start_time);
    const end = new Date(end_time);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const startString = start.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    const endString = end.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    const dateString = start.toLocaleDateString('en-US', options);
    return `${startString} - ${endString}, ${dateString}`;
};

export const getOrganizationIndex = () => {
    const organizationIndex = getDecryptedData('organizationIndex');
    return organizationIndex;
};

export const getOrganizationId = () => {
    const organizationId = getDecryptedData('organizationId');
    return organizationId;
}

