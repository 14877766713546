import { useRef, useState, useEffect, useCallback } from "react";
import { throttle } from 'lodash';
import { useNavigate } from "react-router-dom";
import { FiCompass, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { logOut } from "../../Features/Auth/AuthSlice";
import { useDispatch } from "react-redux";
import './ProfileDropdown.css';

// Avatar component optimized
const UserAvatar = ({ firstName, lastName, onClick }) => {
    const initials = `${firstName[0]}${lastName[0]}`;
    return (
        <div
            id="avatar"
            onClick={onClick}
            role="button"
            aria-label="User Avatar"
            style={{ userSelect: 'none', cursor: 'pointer' }}
        >
            {initials}
        </div>
    );
};

const ProfileDropdown = ({ username, email }) => {
    const [isProfileOptionsOpen, setIsProfileOptionsOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const wrapperRef = useRef(null);
    const dropdownRef = useRef(null);
    const avatarRef = useRef(null);

    const toggleOptions = useCallback(() => {
        setIsProfileOptionsOpen(prev => !prev);
    }, []);

    const logOutUser = useCallback(() => {
        localStorage.clear();
        try {
            dispatch(logOut());
            navigate('/v2');
        } catch (error) {
            console.error('Logout error:', error);
        }
    }, [dispatch, navigate]);

    useEffect(() => {
        const handleMouseEnter = () => {
            setIsProfileOptionsOpen(true);
        };
        avatarRef.current.addEventListener("mouseenter", handleMouseEnter);
        return () => {
            avatarRef.current?.removeEventListener("mouseenter", handleMouseEnter);
        };
    }, []);

    const handleMouseMove = useCallback(
        throttle((event) => {
            if (!isProfileOptionsOpen) return;

            const avatarRect = avatarRef.current ? avatarRef.current.getBoundingClientRect() : null;
            const dropdownRect = dropdownRef.current ? dropdownRef.current.getBoundingClientRect() : null;

            if (
                avatarRect &&
                (
                    event.clientX < avatarRect.left ||
                    event.clientX > avatarRect.right ||
                    event.clientY < avatarRect.top ||
                    event.clientY > avatarRect.bottom
                ) &&
                dropdownRect &&
                (
                    event.clientX < dropdownRect.left ||
                    event.clientX > dropdownRect.right ||
                    event.clientY < dropdownRect.top ||
                    event.clientY > dropdownRect.bottom
                )
            ) {
                setIsProfileOptionsOpen(false);
            }
        }, 100),
        [isProfileOptionsOpen]
    );


    useEffect(() => {
        if (isProfileOptionsOpen) {
            document.addEventListener("mousemove", handleMouseMove);
        } else {
            document.removeEventListener("mousemove", handleMouseMove);
        }
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
        };
    }, [isProfileOptionsOpen, handleMouseMove]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsProfileOptionsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            ref={wrapperRef}
            className="nav-bar-dropdown-container-profile"
        >
            <div ref={avatarRef}>
                <UserAvatar
                    firstName={username.split(' ')[0]}
                    lastName={username.split(' ')[1]}
                    onClick={toggleOptions}
                />
            </div>
            <div
                className="dropdown-header-profile"
                role="button"
                aria-expanded={isProfileOptionsOpen}
                onClick={toggleOptions}
            >
                <div className="dropdown-header-icon">
                    {isProfileOptionsOpen ? <FiChevronUp className="dropdown-chevron-profile" /> : <FiChevronDown className="dropdown-chevron-profile" />}
                </div>
            </div>
            {isProfileOptionsOpen && (
                <div ref={dropdownRef} className="dropdown-options-container-profile">
                    <div className="dropdown-options-list-profile">
                        <div className="dropdown-profile-username">
                            <span>{username}</span>
                        </div>
                        <div className="dropdown-profile-email">
                            <span>{email}</span>
                        </div>
                        <div className="dropdown-profile-divider"></div>
                        <div className="dropdown-profile-icon-container" onClick={logOutUser}>
                            <FiCompass className="dropdown-profile-icon" />
                            <div className="dropdown-profile-text">Logout</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfileDropdown;
